import React from 'react'
import { SingleDatePicker } from 'react-dates'
import styled, { css } from 'styled-components'
import { Row, Col, Button } from 'reactstrap'
import moment from 'moment'
import { Input } from '../FormGroups'
import SesionCard from './SesionCard'
import ModalCheckout from './ModalCheckout'
import { withRouter } from 'react-router-dom'
import { withContext } from '../../context/withContext'
import Logos from '../../static/images/logos tarjetas.png'

class Cart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // focused: props.autoFocus,
            date: moment(),
            modalPaymentWait: false,
            OpenModalCheckout: false,
            quantity: 1,
            quantityKids: 0,
            selected_price: 0,
            data: {
                sesion: null,
            },
        }

        this.onDateChange = this.onDateChange.bind(this)
        this.onFocusChange = this.onFocusChange.bind(this)
    }

    onDateChange(date) {
        this.setState({ date })
    }

    onFocusChange({ focused }) {
        this.setState({ focused })
    }

    handleBuy = () => {
        this.setState({ OpenModalCheckout: true })
    }
    handleCheckoutModal = (created, data) => {
        this.setState({ OpenModalCheckout: false })
        if (typeof created !== 'object' && created === true) {
            this.props.history.push(`/thank-you/${data.uid}`)
        }
    }

    handleSelect = (val) => {
        let data = this.state.data
        data.sesion = val
        this.setState({ data })
    }

    increaseCount = (val) => {
        let qty = this.state.quantity
        let qtyKids = this.state.quantityKids
        if (val === 'kids') {
            qtyKids = qtyKids + 1
        } else {
            qty = qty + 1
        }
        this.setState({ quantity: qty, quantityKids: qtyKids })
    }

    decreaseCount = (val) => {
        let qty = this.state.quantity
        let qtyKids = this.state.quantityKids

        if (val === 'kids') {
            qtyKids = qtyKids > 0 ? qtyKids - 1 : 0
        } else {
            qty = qty > 0 ? qty - 1 : 0
        }

        this.setState({ quantity: qty, quantityKids: qtyKids })
    }

    isBlocked = (date) => {
        let date_ = date.format('YYYY-MM-DD')
        let find = this.props.excursion.dates_locked.find((e) => e.date === date_)
        return typeof find !== 'undefined'
    }

    changeSeatPrice = (e) => {
        let excursion = this.props.excursion
        let index = e.target.value
        let item = excursion.price_seats[index]
        this.setState({ quantity: item.qty_people, selected_price: item.price })
    }

    calculatePrice = (amount) => {
        let rate = this.props.context.exchange_rate
        if (rate > 0) {
            return amount * rate
        } else {
            return amount
        }
    }

    toggleModalWait = () => {
        this.props.updateContextVal({
            payment_status: 'pending',
        })
    }

    render() {
        const { quantity, date, data, OpenModalCheckout } = this.state
        const { increaseCount, decreaseCount, handleSelect } = this
        const excursion = this.props.excursion
        // autoFocus and initialDate are helper props for the example wrapper but are not
        // props on the SingleDatePicker itself and thus, have to be omitted.
        return (
            <>
                {this.props.context.payment_status === 'pending' && (
                    <ModalPaymentWaitView>
                        <div className="container-info">
                            <img
                                className="card-img"
                                src="https://puntacanaapp.s3.us-east-2.amazonaws.com/uploads/excursions/2023/7/qDzzzDGK9U-insert-card.png"
                            />
                            <h2>Inserta tu tarjeta para proceder con el pago.</h2>
                            <Logo src={Logos} />
                        </div>
                    </ModalPaymentWaitView>
                )}
                <Container>
                    {OpenModalCheckout && (
                        <ModalCheckout
                            open={OpenModalCheckout}
                            showModalWait={this.toggleModalWait}
                            toggle={this.handleCheckoutModal}
                            adult_qty={this.state.quantity}
                            date={this.state.date}
                            kids_qty={this.state.quantityKids}
                            adult_price={excursion?.adult_price}
                            kids_price={excursion?.kids_price}
                            price_seat={this.state.selected_price}
                            excursion={excursion?.id}
                            excursion_type={excursion?.excursion_type}
                        />
                    )}

                    <h5>
                        <div className="d-inline step">1-</div>
                        {this.props.context.lang === 'spanish' && 'Selecciona la fecha'}
                        {this.props.context.lang === 'english' && 'Select the date'}
                        {this.props.context.lang === 'german' && 'Wählen Sie das Datum'}
                        {this.props.context.lang === 'french' && 'Sélectionnez la date'}
                        {this.props.context.lang === 'russian' && 'Выберите дату'}
                    </h5>
                    <DateContainer>
                        {excursion !== null && (
                            <SingleDatePicker
                                id="date_input"
                                date={date}
                                focused={true}
                                numberOfMonths={1}
                                // daySize={65}
                                isDayBlocked={this.isBlocked}
                                onDateChange={this.onDateChange}
                                onFocusChange={this.onFocusChange}
                            />
                        )}
                    </DateContainer>

                    {excursion !== null && typeof excursion.sesions !== 'undefined' && excursion.sesions.length > 0 && (
                        <>
                            <h5>
                                <div className="d-inline step">2-</div>
                                {this.props.context.lang === 'spanish' && 'Horarios / Opciones'}
                                {this.props.context.lang === 'english' && 'Hours / Options'}
                                {this.props.context.lang === 'german' && 'Stunden / Optionen'}
                                {this.props.context.lang === 'french' && 'Heures / Options'}
                                {this.props.context.lang === 'russian' && 'Часы / Опции'}
                            </h5>
                            <SectionContainer>
                                <div>
                                    {excursion.sesions.map((e, key) => (
                                        <SesionCard
                                            data={data}
                                            sesion={e}
                                            key={key}
                                            onContextMenu={(e) => e.preventDefault()}
                                            onClick={() => handleSelect(e.id)}
                                        />
                                    ))}
                                </div>
                            </SectionContainer>
                        </>
                    )}

                    <h5 className="mt-4">
                        <div className="d-inline step">
                            {excursion !== null &&
                            typeof excursion.sesions !== 'undefined' &&
                            excursion.sesions.length > 0
                                ? '3'
                                : '2'}
                            -
                        </div>{' '}
                        {this.props.context.lang === 'spanish' && 'Cantidad de Personas'}
                        {this.props.context.lang === 'english' && 'Quantity of people'}
                        {this.props.context.lang === 'german' && 'Anzahl von Leuten'}
                        {this.props.context.lang === 'french' && 'Quantité de personnes'}
                        {this.props.context.lang === 'russian' && 'Количество людей'}
                    </h5>
                    {excursion !== null && excursion.excursion_type !== '6' ? (
                        <>
                            {excursion !== null && excursion.adult_price && (
                                <SectionContainer className="mt-4 mb-4">
                                    <div className="card-body">
                                        <Row style={{ padding: 0 }}>
                                            <Col xs={4} className="flex-center">
                                                {this.props.context.lang === 'spanish' && 'Cant. de Adultos'}
                                                {this.props.context.lang === 'english' && 'Qty of Adults'}
                                                {this.props.context.lang === 'german' && 'Anzahl der Erwachsenen'}
                                                {this.props.context.lang === 'french' && 'Quantité de adultes'}
                                                {this.props.context.lang === 'russian' && 'Количество взрослых'}
                                            </Col>
                                            <Col xs={3} className="flex-center">
                                                <Button
                                                    style={{ margin: 0 }}
                                                    className="w-100"
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onClick={decreaseCount}>
                                                    -
                                                </Button>
                                            </Col>
                                            <Col xs={2} className="flex-center">
                                                <h5>{this.state.quantity}</h5>
                                            </Col>
                                            <Col xs={3} className="flex-end">
                                                <Button
                                                    style={{ margin: 0 }}
                                                    className="w-100"
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onClick={increaseCount}>
                                                    +
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </SectionContainer>
                            )}

                            {excursion !== null && excursion.kids_price && (
                                <SectionContainer className="mt-4 mb-4">
                                    <div className="card-body">
                                        <Row style={{ padding: 0 }}>
                                            <Col xs={4} className="flex-center">
                                                {this.props.context.lang === 'spanish' && 'Cant. de niños (3 a 7 años)'}
                                                {this.props.context.lang === 'english' &&
                                                    'Qty of children (3 to 7 years)'}
                                                {this.props.context.lang === 'german' &&
                                                    'Anzahl der kinder (3 bis 7 Jahre)'}
                                                {this.props.context.lang === 'french' &&
                                                    'Quantité de enfants (3 à 7 ans)'}
                                                {this.props.context.lang === 'russian' &&
                                                    'Количество дети (от 3 до 7 лет)'}
                                            </Col>
                                            <Col xs={3} className="flex-center">
                                                <Button
                                                    style={{ margin: 0 }}
                                                    className="w-100"
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onClick={() => decreaseCount('kids')}>
                                                    -
                                                </Button>
                                            </Col>
                                            <Col xs={2} className="flex-center">
                                                <h5>{this.state.quantityKids}</h5>
                                            </Col>
                                            <Col xs={3} className="flex-end">
                                                <Button
                                                    style={{ margin: 0 }}
                                                    className="w-100"
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onClick={() => increaseCount('kids')}>
                                                    +
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </SectionContainer>
                            )}
                        </>
                    ) : (
                        <SectionContainer className="mt-4 mb-4">
                            <div className="card-body">
                                <Row style={{ padding: 0 }}>
                                    <Col xs={4} className="flex-center">
                                        {this.props.context.lang === 'spanish' && 'Elije una opción'}
                                        {this.props.context.lang === 'english' && 'Choose an option'}
                                        {this.props.context.lang === 'german' && 'Wähle eine Option'}
                                        {this.props.context.lang === 'french' && 'Choisis une option'}
                                        {this.props.context.lang === 'russian' && 'Выберите опцию'}
                                    </Col>
                                    <Col xs={8} className="flex-center">
                                        <select onChange={this.changeSeatPrice} className="form-control">
                                            <option selected disabled style={{ display: 'none' }}></option>
                                            {excursion !== null &&
                                                excursion.price_seats.map((e, key) => (
                                                    <option value={key}>{e.description}</option>
                                                ))}
                                        </select>
                                    </Col>
                                </Row>
                            </div>
                        </SectionContainer>
                    )}

                    {excursion !== null && !excursion.reservation_only && (
                        <PriceCard>
                            <div className="card-body flex-end">
                                <h5 style={{ margin: 0 }}>
                                    {this.props.context.exchange_rate == 0 && 'US$'}
                                    {excursion.excursion_type === '6' ? (
                                        <span className="mx-1">{this.calculatePrice(this.state.selected_price)}</span>
                                    ) : (
                                        <span className="mx-1">
                                            {this.calculatePrice(
                                                excursion.adult_price * this.state.quantity +
                                                    excursion.kids_price * this.state.quantityKids
                                            )}
                                        </span>
                                    )}
                                    {this.props.context.exchange_rate > 0 && 'RD$'}
                                </h5>
                            </div>
                        </PriceCard>
                    )}
                    <ButtonCart
                        disabled={quantity === 0}
                        onContextMenu={(e) => e.preventDefault()}
                        onClick={this.handleBuy}>
                        {excursion !== null && excursion.reservation_only ? (
                            <span>
                                {this.props.context.lang === 'spanish' && 'Reservar'}
                                {this.props.context.lang === 'english' && 'Book'}
                                {this.props.context.lang === 'german' && 'Reservieren'}
                                {this.props.context.lang === 'french' && 'Reserver'}
                                {this.props.context.lang === 'russian' && 'Резерв'}
                            </span>
                        ) : (
                            <span>
                                {this.props.context.lang === 'spanish' && 'Comprar'}
                                {this.props.context.lang === 'english' && 'Buy'}
                                {this.props.context.lang === 'german' && 'Kaufen'}
                                {this.props.context.lang === 'french' && 'Acheter'}
                                {this.props.context.lang === 'russian' && 'Покупать'}
                            </span>
                        )}
                    </ButtonCart>
                    <LogoContent>
                        <Logo src={Logos} />

                        {/* <Logo src={LogoAZul} /> */}
                    </LogoContent>
                </Container>
            </>
        )
    }
}

const Logo = styled.img`
    height: 60px;
    margin-right: 5px;
    border-radius: 0 !important;
    box-shadow: none !important;
`

const LogoContent = styled.div`
    margin-top: 20px;
    text-align: center;
`

const PriceCard = styled.div`
    border: 1px solid #eee;
    display: flex;
    justify-content: flex-end;
`
const Container = styled.div`
    h5 {
        font-weight: 600;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        color: rgb(19, 124, 189);
    }
    .step {
        font-size: 2rem;
        font-weight: 600;
        margin-right: 10px;
    }
    small {
        opacity: 0.8;
    }
    button {
        margin-top: 1rem;
    }
    .block {
        border-top: 1px solid #eee;
    }
`
const DateContainer = styled.div`
    margin-top: -3rem;
    height: 400px;
    .CalendarDay__blocked_calendar {
        background-color: #f00;
        color: #fff;
        position: relative;
        :hover {
            color: #fff;
            background-color: #f00;
        }
    }
`
const SectionContainer = styled.div`
    border: 1px solid #eee;
    .row {
        padding-bottom: 1.5rem;
    }
`

const ButtonCart = styled.button`
    background-color: #316cbe;
    color: #fff;
    transition: all 0.3s ease;
    width: 100%;
    border-radius: 3px;
    padding: 10px;
    border: none;
    outline: none;
    font-weight: bold;
    :hover {
        opacity: 0.8;
    }
`

const ModalPaymentWaitView = styled.div`
    position: fixed;
    float: left;
    top: 0;
    background-color: #00000066;
    width: 100%;
    height: 100vh;
    left: 0;
    z-index: 9999999;
    display: flex;
    justify-content: center;
    align-items: center;
    .container-info {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        padding: 4rem 15px;
        width: 100%;
        margin: 0 15px;
        border-radius: 20px;
        flex-flow: column wrap;
    }
    h2 {
        margin-top: 15px;
        text-align: center;
        margin-bottom: 45px;
        color: #444;
    }
    .card-img {
        width: 20rem;
        border: none !important;
        box-shadow: none !important;
        margin-bottom: 15px !important;
    }
`
export default withRouter(withContext(Cart))
