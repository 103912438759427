import { API } from '../utils/config'

export function FilterCategories(qs) {
    return API(`/excursions/categories/${qs}`)
}
export function FilterExcursions(qs) {
    return API(`/excursions/${qs}`)
}
export function GetExcursion(uid) {
    return API(`/excursions/${uid}/`)
}
