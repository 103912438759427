import React from 'react'
import UserProvider, { UserConsumer } from './context/UserProvider'
import { BrowserRouter as Router } from 'react-router-dom'
import Container from './Layouts/Container'
// CSS Imports
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'
import 'react-image-lightbox/style.css'
import 'react-alice-carousel/lib/alice-carousel.css'
import 'tabler-react/dist/Tabler.css'
import './static/css/styles.css'

function App() {
    return (
        <Router>
            <UserProvider>
                <UserConsumer>{({ state }) => <Container user={state} />}</UserConsumer>
            </UserProvider>
        </Router>
    )
}

export default App
