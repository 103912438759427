import React from 'react'
import Principal from './Principal'
import Login from '../Pages/Login'
import { withContext } from '../context/withContext'

function Container(props) {
    let isLogged = props.context.isLogged

    if (isLogged) {
        return <Principal user={props.user} />
    } else {
        return <Login />
    }
}

export default withContext(Container)
