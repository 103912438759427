import React from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import Sticky from 'react-sticky-el'
import TopSlider from '../components/sliders/TopSlider'
import { withContext } from '../context/withContext'
function Topbar(props) {
    return (
        <Sticky>
            <TopbarContainer>
                <div className="container-fluid">
                    <Row>
                        <Col xs="2">
                            <Logo>
                                <img src={props.context.logo1} alt="logo" />
                            </Logo>
                        </Col>
                        <Col xs="8">
                            <TopSlider />
                        </Col>
                        <Col xs="2">
                            <Logo>
                                <img
                                    src={props.context.logo2 === null ? props.context.logo1 : props.context.logo2}
                                    alt="logo"
                                />
                            </Logo>
                        </Col>
                    </Row>
                </div>
            </TopbarContainer>
        </Sticky>
    )
}

export default withContext(Topbar)

const Logo = styled.div`
    img {
        width: 100%;
        height: 100px;
        padding: 10px;
    }
`

const TopbarContainer = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: #fff;
    z-index: 100;
    box-shadow: 0px 0px 13px #94949475;
    background-color: #ffffff;
`
