import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import ChangeLanguage from '../components/configuration/ChangeLanguage'
import Purchase from '../components/events/Purchase'
import WeatherModal from '../components/elements/WeatherModal'
import WebsiteEmbed from '../components/elements/WebsiteEmbed'
import { withContext } from '../context/withContext'
import Logos from '../static/images/logos tarjetas.png'
function Footer(props) {
    const [modal, toggle] = useState(false)
    const [modalWeather, toggleWeather] = useState(false)
    const [modalWebsite, toggleWebsite] = useState(false)

    return (
        <>
            {props.purchase && (
                <Purchase onClick={props.onClick} excursion={props.excursion} buttonActive={props.buttonActive} />
            )}
            <FooterContainer>
                {props.showBack && (
                    <Icon onClick={props.showBackFunc ? props.showBackFunc : props.history.goBack}>
                        <img src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2019/12/wvPSa5TymJ-back.png" />
                    </Icon>
                )}
                {!props.showBack && (
                    <Icon active={props.active === 'hotel'} onClick={props.handleHotel}>
                        <img src={props.context.logo1} />
                    </Icon>
                )}
                <Icon onClick={() => toggle(true)}>
                    <img src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2019/12/GPtH5p7pz7-translate.png" />
                </Icon>
                {!props.showBack && (
                    <Icon active={props.active === 'map'}>
                        <Link to={'/map'}>
                            <img src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2019/12/pYyZMoxTfD-question.png" />
                        </Link>
                    </Icon>
                )}
                {props.showBack && (
                    <Icon
                        onClick={props.showBackFunc && props.showBackFunc}
                        active={props.active === 'home'}
                        style={{ borderColor: '#137cbd' }}>
                        <Link id="home-button" to="/">
                            <img src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2019/12/tnPbajfVhz-home.png" />
                        </Link>
                    </Icon>
                )}
                <Icon onClick={() => toggleWeather(true)}>
                    <img src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2019/12/Kw8wNT37qx-weather.png" />
                </Icon>
                <Icon onClick={() => alert('Opción aún no disponible.')} active={props.active === 'home'}>
                    <img src="https://d2p0bx8wfdkjkb.cloudfront.net/static/documents/AlterEstate/2019/12/JiMt6P2QkN-plane.png" />
                </Icon>
            </FooterContainer>
            <AddressContainer>
                <Row>
                    <Col>
                        <VerticalAlign>
                            <span>
                                {props.context.address1}, {props.context.address2}
                            </span>
                        </VerticalAlign>
                    </Col>
                    <Col xs="12" md="12" lg="5">
                        <VerticalAlign>
                            <span>
                                Tel: <a href={`tel:${props.context.phone}`}>{props.context.phone}</a>
                            </span>{' '}
                            |{' '}
                            <span>
                                Email: <a href={`mailto:${props.context.email}`}>{props.context.email}</a>
                            </span>
                        </VerticalAlign>
                    </Col>
                    <Col xs="12" md="12" lg="3">
                        <VerticalAlign>
                            <LogosImg src={Logos} />
                        </VerticalAlign>
                    </Col>
                </Row>
            </AddressContainer>
            <ChangeLanguage modal={modal} toggle={() => toggle(!modal)} />
            <WeatherModal modal={modalWeather} toggle={() => toggleWeather(!modalWeather)} />
            <WebsiteEmbed modal={modalWebsite} toggle={() => toggleWebsite(!modalWebsite)} />
        </>
    )
}

const LogosImg = styled.img`
    max-width: 100%;
    max-height: 35px;
`

const VerticalAlign = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const AddressContainer = styled.div`
    z-index: 999;
    position: fixed;
    bottom: 0vh;
    width: 100%;
    height: 45px;
    font-size: 10px;
    background-color: #fff;
    color: #868cad;
    padding: 5px 15px;
    text-align: center;
    @media screen and (max-width: 993px) {
        height: 85px;
    }
    span {
        margin: 0 10px;
    }
`
const FooterContainer = styled.div`
    z-index: 999;
    position: fixed;
    bottom: 30px;
    left: 0;
    width: 100%;
    height: 135px;
    padding-bottom: 10px;
    box-sizing: border-box;
    background: #fff;
    border-top: 3px solid #eee;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 17%,
        rgba(228, 228, 228, 1) 37%,
        rgba(255, 255, 255, 1) 58%,
        rgba(255, 255, 255, 1) 100%
    );
    img {
        width: 100%;
    }
    @media screen and (max-width: 993px) {
        bottom: 80px;
    }
`
const Icon = styled.div`
    width: 100px;
    height: 100px;
    background: rgb(255, 255, 255);
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 50%, rgba(204, 204, 204, 1) 100%);
    padding: 0.8rem;
    border-radius: 8px;
    border: 2px solid #bfbfbf;
    transition: all 0.3s;
    cursor: pointer;
    :active {
        background: rgb(55, 167, 242);
        background: radial-gradient(circle, rgba(55, 167, 242, 1) 50%, rgba(43, 124, 178, 1) 100%);
        border-color: #2d86c0;
    }
    a {
        position: relative;
        float: left;
        width: 100%;
        height: 100%;
    }
    ${(props) =>
        props.active &&
        css`
            background: rgb(55, 167, 242);
            background: radial-gradient(circle, rgba(55, 167, 242, 1) 50%, rgba(43, 124, 178, 1) 100%);
            border-color: #2d86c0;
        `}
    img {
        pointer-events: none;
    }
`
export default withRouter(withContext(Footer))
