import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { RemountingRoute } from '../components/RemountingRoute'
// Local import
import Detail from '../Pages/Detail'
import Home from '../Pages/Home'
import Map from '../Pages/Map'
import Order from '../Pages/Detail/order'
import ThankYouPage from '../Pages/ThankYouPage/index'
import declined from '../Pages/ThankYouPage/declined'

function Content(props) {
    const [debounce, setDebounce] = React.useState(0)

    const handleRedirectHome = () => {
        let _debounce = debounce
        clearTimeout(_debounce)
        _debounce = setTimeout(() => {
            window.location.href = '/'
        }, 600000)
        setDebounce(_debounce)
    }

    return (
        <Container id="content-principal" onClick={handleRedirectHome}>
            <Route exact path="/" component={Home} />
            <RemountingRoute exact path="/detail/:uid" component={Detail} />
            <Route exact path="/detail/:uid/order" component={Order} />
            <Route exact path="/map" component={Map} />
            <Route exact path="/thank-you/:uid" component={ThankYouPage} />
            <Route exact path="/declined" component={declined} />
        </Container>
    )
}
const Container = styled.div``
export default Content
