import React from 'react'
import styled from 'styled-components'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import renderHTML from 'react-render-html'
import Logos from '../../static/images/logos tarjetas.png'

export default function ModalPoliciesDataTransfer(props) {
    return (
        <Modal isOpen={props.open} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>
                {props.lang === 'spanish' && 'Políticas de Transmisión de datos'}
                {props.lang === 'english' && 'Privacy / Cancellation Policies'}
                {props.lang === 'german' && 'Datenübertragungsrichtlinien'}
                {props.lang === 'french' && 'Politiques de transmission de données'}
                {props.lang === 'russian' && 'Политики передачи данных'}
            </ModalHeader>
            <ModalBody>
                {props.policies !== '' && props.policies !== null && renderHTML(props.policies)}
                <LogoContent>
                    <Logo src={Logos} />
                </LogoContent>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.toggle}>
                    {props.lang === 'spanish' && 'Cerrar'}
                    {props.lang === 'english' && 'Close'}
                    {props.lang === 'german' && 'Schließen'}
                    {props.lang === 'french' && 'Fermer'}
                    {props.lang === 'russian' && 'Закрыть'}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const Logo = styled.img`
    height: 60px;
    margin-right: 5px;
    border-radius: 0 !important;
    box-shadow: none !important;
`

const LogoContent = styled.div`
    margin-top: 20px;
    text-align: center;
`
