import React from 'react'
import styled from 'styled-components'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { CreateReservation, GetHashAzul } from '../../api/reservations'
import Cookies from 'universal-cookie'
import { withContext } from '../../context/withContext'
import ModalPolicies from './ModalPolicies'
import ModalPoliciesDataTransfer from './ModalPoliciesDataTransfer'
import { Spinner } from '@blueprintjs/core'
import LogoAzul from '../../static/images/logo_azul.jpeg'
import Logos from '../../static/images/logos tarjetas.png'
const cookies = new Cookies()

function ModalCheckout(props) {
    const [data_azul, setDataAzul] = React.useState(null)
    const [accept_policies, setAcceptedPolicies] = React.useState(false)
    const [loading, set_loading] = React.useState(false)
    const [room, set_room] = React.useState('')
    const [showPolicies, setShowPolicies] = React.useState(false)
    const [showPoliciesDataTransfer, setShowPoliciesDataTransfer] = React.useState(false)
    const [lang_tour, set_lang_tour] = React.useState(1)
    const [email, set_email] = React.useState('')
    const [full_name, set_full_name] = React.useState('')
    // const [qty, set_qty] = React.useState(null)
    const adult_qty = props.adult_qty
    const kids_qty = props.kids_qty
    const adult_price = props.adult_price
    const kids_price = props.kids_price
    const excursion = props.excursion
    const date = props.date

    const handleCreateReservation = async (e) => {
        let sum = adult_qty * adult_price + kids_qty * kids_price
        set_loading(true)
        let total = props.excursion_type === '6' ? props.price_seat : sum
        e.preventDefault()
        let data = {
            room: room,
            adult_qty: adult_qty,
            kids_qty: kids_qty,
            adult_price: adult_price,
            kids_price: kids_price,
            excursion: excursion,
            room: room,
            date: date,
            lang_tour: lang_tour,
            email: email,
            full_name: full_name,
            total: total,
        }
        try {
            let response = await CreateReservation(data, cookies.get('token'))
            props.toggle(true, response.data)
            set_loading(false)
        } catch (err) {
            console.log(err)
            set_loading(false)
        }
    }

    const SendDataPay = () => {
        let sum = adult_qty * adult_price + kids_qty * kids_price
        let total = props.excursion_type === '6' ? props.price_seat : sum
        let dataToSend = {
            room: room,
            adult_qty: adult_qty,
            kids_qty: kids_qty,
            adult_price: adult_price,
            kids_price: kids_price,
            excursion: excursion,
            room: room,
            date: date,
            lang_tour: lang_tour,
            email: email,
            full_name: full_name,
            total: total,
            exchange_rate: props.context.exchange_rate,
            token: props.context.token,
            raspberry_ip: props.context.raspberry_ip,
        }
        if (props.showModalWait) props.showModalWait()
        window.ReactNativeWebView.postMessage(JSON.stringify(dataToSend))
    }

    const getDataHash = async (e) => {
        e.preventDefault()
        set_loading(true)

        try {
            let sum = adult_qty * adult_price + kids_qty * kids_price
            let total = props.excursion_type === '6' ? props.price_seat : sum

            let data = {
                room: room,
                adult_qty: adult_qty,
                kids_qty: kids_qty,
                adult_price: adult_price,
                kids_price: kids_price,
                excursion: excursion,
                room: room,
                date: date,
                lang_tour: lang_tour,
                email: email,
                full_name: full_name,
                total: total,
            }

            let response = await GetHashAzul(data)
            setDataAzul(response.data)
            setTimeout(() => {
                document.getElementById('form-azul').submit()
                set_loading(false)
            }, 1500)
        } catch (err) {
            console.log(err)
            set_loading(false)
        }
    }

    function checkValid(data) {
        if (data.room && data.full_name && data.email && accept_policies) {
            return false
        }
        return true
    }

    const { checkout_method } = props.context

    return (
        <Modal isOpen={props.open} toggle={props.toggle}>
            <ModalPolicies
                lang={props.context.lang}
                policies={props.context.privacy_policies}
                open={showPolicies}
                toggle={() => setShowPolicies(false)}
            />
            <ModalPoliciesDataTransfer
                lang={props.context.lang}
                policies={props.context.policies}
                open={showPoliciesDataTransfer}
                toggle={() => setShowPoliciesDataTransfer(false)}
            />
            <ModalHeader toggle={props.toggle}>
                {props.context.lang === 'spanish' && 'Finalizar Reservación'}
                {props.context.lang === 'english' && 'Finish Reservation'}
                {props.context.lang === 'german' && 'Reservierung beenden'}
                {props.context.lang === 'french' && 'Terminer la réservation'}
                {props.context.lang === 'russian' && 'Завершить бронирование'}
            </ModalHeader>
            <form onSubmit={checkout_method === 2 ? getDataHash : handleCreateReservation}>
                <ModalBody>
                    <Title>
                        {props.context.lang === 'spanish' &&
                            'Complete el siguiente formulario para finalizar su reservación.'}
                        {props.context.lang === 'english' &&
                            'Complete the following form to finalize your reservation.'}
                        {props.context.lang === 'german' &&
                            'Füllen Sie das folgende Formular aus, um Ihre Reservierung abzuschließen.'}
                        {props.context.lang === 'french' &&
                            'Remplissez le formulaire suivant pour finaliser votre réservation.'}
                        {props.context.lang === 'russian' && 'Заполните следующую форму, чтобы завершить бронирование.'}
                    </Title>
                    <Row>
                        <Col>
                            <label className="form-group">
                                {props.context.lang === 'spanish' && 'Número de habitación: '}
                                {props.context.lang === 'english' && 'Room number:'}
                                {props.context.lang === 'german' && 'Zimmernummer:'}
                                {props.context.lang === 'french' && 'Numéro de chambre:'}
                                {props.context.lang === 'russian' && 'Номер комнаты:'}

                                <Required className="required">*</Required>
                                <input
                                    required
                                    type="text"
                                    value={room}
                                    onChange={(e) => set_room(e.target.value)}
                                    className="form-control"
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label className="form-group">
                                {props.context.lang === 'spanish' && 'Nombre completo:'}
                                {props.context.lang === 'english' && 'Full name:'}
                                {props.context.lang === 'german' && 'Vollständiger Name:'}
                                {props.context.lang === 'french' && 'Nom complet:'}
                                {props.context.lang === 'russian' && 'ФИО:'}
                                <Required className="required">*</Required>
                                <input
                                    required
                                    type="text"
                                    value={full_name}
                                    onChange={(e) => set_full_name(e.target.value)}
                                    className="form-control"
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label className="form-group">
                                {props.context.lang === 'spanish' && 'Correo electrónico:'}
                                {props.context.lang === 'english' && 'Email:'}
                                {props.context.lang === 'german' && 'Email:'}
                                {props.context.lang === 'french' && 'Courrier électronique:'}
                                {props.context.lang === 'russian' && 'Эл. адрес:'}
                                <Required className="required">*</Required>
                                <input
                                    required
                                    type="email"
                                    value={email}
                                    onChange={(e) => set_email(e.target.value)}
                                    className="form-control"
                                />
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label className="form-group">
                                {props.context.lang === 'spanish' && 'Idioma del guia:'}
                                {props.context.lang === 'english' && 'Guide language:'}
                                {props.context.lang === 'german' && 'Leitsprache:'}
                                {props.context.lang === 'french' && 'Langue du guide:'}
                                {props.context.lang === 'russian' && 'Язык руководства:'}
                                <Required className="required">*</Required>
                                <select
                                    required
                                    id="lang_tour"
                                    className="form-control"
                                    value={lang_tour}
                                    onChange={(e) => set_lang_tour(e.target.value)}>
                                    <option value="1" selected>
                                        {props.context.lang === 'spanish' && 'Español'}
                                        {props.context.lang === 'english' && 'Spanish'}
                                        {props.context.lang === 'german' && 'Spanisch'}
                                        {props.context.lang === 'french' && 'Espagnol'}
                                        {props.context.lang === 'russian' && 'испанский'}
                                    </option>
                                    <option value="2">
                                        {props.context.lang === 'spanish' && 'Inglés'}
                                        {props.context.lang === 'english' && 'English'}
                                        {props.context.lang === 'german' && 'Englisch'}
                                        {props.context.lang === 'french' && 'Anglais'}
                                        {props.context.lang === 'russian' && 'английский'}
                                    </option>
                                    <option value="3">
                                        {props.context.lang === 'spanish' && 'Alemán'}
                                        {props.context.lang === 'english' && 'German'}
                                        {props.context.lang === 'german' && 'Deutsche'}
                                        {props.context.lang === 'french' && 'Allemand'}
                                        {props.context.lang === 'russian' && 'Немецкий'}
                                    </option>
                                    <option value="4">
                                        {props.context.lang === 'spanish' && 'Francés'}
                                        {props.context.lang === 'english' && 'French'}
                                        {props.context.lang === 'german' && 'Französisch'}
                                        {props.context.lang === 'french' && 'Français'}
                                        {props.context.lang === 'russian' && 'французский язык'}
                                    </option>
                                    <option value="5">
                                        {props.context.lang === 'spanish' && 'Ruso'}
                                        {props.context.lang === 'english' && 'Russian'}
                                        {props.context.lang === 'german' && 'Russisch'}
                                        {props.context.lang === 'french' && 'Russe'}
                                        {props.context.lang === 'russian' && 'русский'}
                                    </option>
                                </select>
                            </label>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center" xs="1" style={{ paddingRight: 0 }}>
                            <input
                                type="checkbox"
                                required
                                onChange={(e) => setAcceptedPolicies(e.target.checked)}
                                style={{ display: 'inline-block', marginRight: '10px', marginTop: '5px' }}
                            />{' '}
                        </Col>
                        <Col style={{ paddingLeft: 0 }}>
                            <label className="form-group" style={{ display: 'inline-block', fontSize: '13px' }}>
                                {props.context.lang === 'spanish' && 'Aceptar '}
                                {props.context.lang === 'english' && 'Accept '}
                                {props.context.lang === 'german' && 'Akzeptieren '}
                                {props.context.lang === 'french' && 'Accepter '}
                                {props.context.lang === 'russian' && 'Принять '}
                                <a
                                    onClick={() => setShowPolicies(true)}
                                    style={{ color: '#2860B3', cursor: 'pointer' }}>
                                    {props.context.lang === 'spanish' && 'Políticas de Privacidad / Cancelación'}
                                    {props.context.lang === 'english' && 'Policies Data Transmission'}
                                    {props.context.lang === 'german' && 'Datenschutz- / Stornierungsrichtlinien'}
                                    {props.context.lang === 'french' && "Politique de Donfidentialité / D'annulation"}
                                    {props.context.lang === 'russian' && 'Политика конфиденциальности / отмены'}
                                </a>{' '}
                                {props.context.policies !== '' && props.context.policies !== null && (
                                    <>
                                        y{' '}
                                        <a
                                            onClick={() => setShowPoliciesDataTransfer(true)}
                                            style={{ color: '#2860B3', cursor: 'pointer' }}>
                                            {props.context.lang === 'spanish' && 'Políticas de Transmisión de datos'}
                                            {props.context.lang === 'english' && 'Privacy / Cancellation Policies'}
                                            {props.context.lang === 'german' && 'Datenübertragungsrichtlinien'}
                                            {props.context.lang === 'french' && 'Politiques de transmission de données'}
                                            {props.context.lang === 'russian' && 'Политики передачи данных'}
                                        </a>
                                    </>
                                )}
                            </label>
                        </Col>
                    </Row>

                    <LogoContent>
                        <LogoCards src={Logos} />
                    </LogoContent>
                </ModalBody>
                <ModalFooterCustom>
                    {props.context.checkout_method !== 2 ? (
                        <Button color="success" type="submit" disabled={loading}>
                            {loading ? (
                                <Spinner size="14" />
                            ) : (
                                <>
                                    {props.context.lang === 'spanish' && 'Reservar'}
                                    {props.context.lang === 'english' && 'Booking'}
                                    {props.context.lang === 'german' && 'Reservieren'}
                                    {props.context.lang === 'french' && 'Réservez'}
                                    {props.context.lang === 'russian' && 'Забронировать'}
                                </>
                            )}
                        </Button>
                    ) : props.context.is_app ? (
                        <ButtonPay
                            type="button"
                            onClick={SendDataPay}
                            className="btn btn-primary"
                            disabled={loading || checkValid({ room, email, full_name })}>
                            {loading ? (
                                <Spinner size="14" />
                            ) : (
                                <>
                                    {props.context.lang === 'spanish' && 'Pagar'}
                                    {props.context.lang === 'english' && 'Pay'}
                                    {props.context.lang === 'german' && 'ReservierenBezahlen'}
                                    {props.context.lang === 'french' && 'Payer'}
                                    {props.context.lang === 'russian' && 'Платить'}
                                </>
                            )}
                        </ButtonPay>
                    ) : (
                        <ButtonAzulOnline
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading || checkValid({ room, email, full_name })}>
                            {loading ? (
                                <Spinner size="14" />
                            ) : (
                                <>
                                    {props.context.lang === 'spanish' && 'Pagar con'}
                                    {props.context.lang === 'english' && 'Pay with'}
                                    {props.context.lang === 'german' && 'ReservierenBezahlen mit'}
                                    {props.context.lang === 'french' && 'Payer avec'}
                                    {props.context.lang === 'russian' && 'Платить чем-либо'}
                                    <Logo src={LogoAzul} />
                                </>
                            )}
                        </ButtonAzulOnline>
                    )}
                    <ButtonCancel color="secondary" onClick={props.toggle} disabled={loading}>
                        {props.context.lang === 'spanish' && 'Cancelar'}
                        {props.context.lang === 'english' && 'Cancel'}
                        {props.context.lang === 'german' && 'Stornieren'}
                        {props.context.lang === 'french' && 'Annuler'}
                        {props.context.lang === 'russian' && 'Отмена'}
                    </ButtonCancel>
                </ModalFooterCustom>
            </form>

            {data_azul !== null && (
                <form id="form-azul" action="https://pagos.azul.com.do/paymentpage/default.aspx" method="post">
                    <input type="hidden" id="MerchantId" name="MerchantId" value={data_azul.MerchantId} />
                    <input type="hidden" id="MerchantName" name="MerchantName" value={data_azul.MerchantName} />
                    <input type="hidden" id="MerchantType" name="MerchantType" value={data_azul.MerchantType} />
                    <input type="hidden" id="CurrencyCode" name="CurrencyCode" value={data_azul.CurrencyCode} />
                    <input type="hidden" id="OrderNumber" name="OrderNumber" value={data_azul.OrderNumber} />
                    <input type="hidden" id="Amount" name="Amount" value={data_azul.Amount} />
                    <input type="hidden" id="ITBIS" name="ITBIS" value={data_azul.ITBIS} />
                    <input type="hidden" id="ApprovedUrl" name="ApprovedUrl" value={data_azul.ApprovedUrl} />
                    <input type="hidden" id="DeclinedUrl" name="DeclinedUrl" value={data_azul.DeclinedUrl} />
                    <input type="hidden" id="CancelUrl" name="CancelUrl" value={data_azul.CancelUrl} />
                    <input
                        type="hidden"
                        id="UseCustomField1"
                        name="UseCustomField1"
                        value={data_azul.UseCustomField1}
                    />
                    <input
                        type="hidden"
                        id="CustomField1Label"
                        name="CustomField1Label"
                        value={data_azul.CustomField1Label}
                    />
                    <input
                        type="hidden"
                        id="CustomField1Value"
                        name="CustomField1Value"
                        value={data_azul.CustomField1Value}
                    />
                    <input
                        type="hidden"
                        id="UseCustomField2"
                        name="UseCustomField2"
                        value={data_azul.UseCustomField2}
                    />
                    <input type="hidden" id="ShowTransactionResult" name="ShowTransactionResult" value="0" />
                    <input type="hidden" id="AuthHash" name="AuthHash" value={data_azul.AuthHash} />
                </form>
            )}
        </Modal>
    )
}

const ButtonAzulOnline = styled.button`
    border-radius: 5px;
    padding-top: 0 !important;
    display: block;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    width: 100%;
    height: 50px;
    font-weight: 400;
    font-size: 24px !important;
    background-color: #233c92 !important;
    padding: 5px;
`
const ButtonPay = styled.button`
    border-radius: 5px;
    padding-top: 0 !important;
    display: block;
    padding-bottom: 0 !important;
    width: 100%;
    font-weight: 400;
    height: 50px;
    font-size: 24px !important;
    color: #fff;
    background-color: #1eba36 !important;
    padding: 5px;
`

const Title = styled.h5`
    margin-bottom: 20px;
    font-weight: 500;
`

const Required = styled.span`
    color: #f00;
`

const Logo = styled.img`
    max-height: 35px;
    margin-left: 10px;
`

const LogoCards = styled.img`
    height: 60px;
    margin-right: 5px;
    border-radius: 0 !important;
    box-shadow: none !important;
`

const LogoContent = styled.div`
    margin-top: 20px;
    text-align: center;
`

const ButtonCancel = styled.button`
    width: 100%;
    display: block;
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    background: #fff;
    border-radius: 5px;
    color: #444;
    border: 1px solid #444;
    padding: 5px;
`
const ModalFooterCustom = styled(ModalFooter)`
    flex-flow: column wrap;
    button {
        margin-left: 0 !important;
        margin-bottom: 10px;
    }
`
export default withContext(ModalCheckout)
