import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import renderHTML from 'react-render-html'
import { withContext } from '../../context/withContext'
function OperatorCard(props) {
    const excursion = props.excursion
    const [readMore, setReadMore] = React.useState(false)

    return (
        <Container readMore={readMore}>
            <div>
                <Row>
                    <Col xs={12}>
                        <div className="media">
                            <img className="mr-3" src={excursion.featured_image} alt={excursion.name} />
                            <div className="media-body">
                                <h5 className="mt-0">{excursion.name}</h5>
                                <div className="description">
                                    {props.context.lang === 'spanish' &&
                                        excursion.description &&
                                        renderHTML(excursion.description)}
                                    {props.context.lang === 'english' &&
                                        excursion.description_en &&
                                        renderHTML(excursion.description_en)}
                                    {props.context.lang === 'german' &&
                                        excursion.description_gr &&
                                        renderHTML(excursion.description_gr)}
                                    {props.context.lang === 'french' &&
                                        excursion.description_fr &&
                                        renderHTML(excursion.description_fr)}
                                    {props.context.lang === 'russian' &&
                                        excursion.description_ru &&
                                        renderHTML(excursion.description_ru)}
                                </div>
                                <ReadMore onClick={() => setReadMore(!readMore)}>
                                    {readMore ? (
                                        <span>
                                            {props.context.lang === 'spanish' && 'Leer menos'}
                                            {props.context.lang === 'english' && 'Read less'}
                                            {props.context.lang === 'german' && 'Lese weniger'}
                                            {props.context.lang === 'french' && 'Lire moins'}
                                            {props.context.lang === 'russian' && 'Читать меньше'}
                                        </span>
                                    ) : (
                                        <span>
                                            {props.context.lang === 'spanish' && 'Leer más'}
                                            {props.context.lang === 'english' && 'Read more'}
                                            {props.context.lang === 'german' && 'Weiterlesen'}
                                            {props.context.lang === 'french' && 'Lire plus'}
                                            {props.context.lang === 'russian' && 'Читать далее'}
                                        </span>
                                    )}
                                </ReadMore>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}
const Container = styled.div`
    .description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: ${(props) => (props.readMore ? 'auto' : '45px')};
        margin-bottom: 10px;
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        strong {
            font-size: 0.7rem !important;
            font-weight: 400 !important;
        }
        div {
            display: inline-block !important;
        }
    }
`

const ReadMore = styled.span`
    cursor: pointer;
    color: #2b79c9;
    font-weight: 500;
`
export default withContext(OperatorCard)
