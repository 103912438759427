import React, { Component } from 'react'
import Cookies from 'universal-cookie'

// import { ValidateToken } from '../API/core'
// import { ReturnVals, ReturnTeamMembers } from '../helpers/returnValue'
const cookies = new Cookies()

const UserContext = React.createContext()

class UserProvider extends Component {
    state = {
        token: cookies.get('token'),
        hotel: cookies.get('hotel'),
        uid: cookies.get('uid'),
        username: cookies.get('username'),
        logo1: null,
        logo2: null,
        lang: 'spanish',
        isLogged: false,
        loadingApp: false,
        categories: [],
        is_app: false,
        payment_status: null,
    }

    updateContextVal = (state) => {
        this.setState(state)
    }

    render() {
        return (
            <UserContext.Provider
                value={{
                    state: this.state,
                    updateContextVal: this.updateContextVal,
                }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

// then make a consumer which will surface it
const UserConsumer = UserContext.Consumer

export default UserProvider
export { UserConsumer }
