import React, { Component } from 'react'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { baseURL as SERVER_URL } from '../../utils/config'
import { Spinner } from '@blueprintjs/core'
import styled from 'styled-components'
import { updateHeaders, APP_TOKEN } from '../../utils/config'
import { withContext } from '../../context/withContext'
import { Row, Col, Container } from 'reactstrap'
import { useLocation } from 'react-router-dom'
// Constantes
const cookies = new Cookies()

function Login(props) {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const [login, setLogin] = React.useState({
        username: '',
        password: '',
    })
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [show, setShow] = React.useState(false)

    const handleChangeField = (e) => {
        let login_ = Object.assign({}, login)
        let id = e.target.getAttribute('id')
        login_[id] = e.target.value
        setLogin(login_)
    }

    const Login = (e) => {
        e.preventDefault()
        setLoading(true)
        setError(false)
        const varValue = searchParams.get('t_')
        axios
            .post(SERVER_URL + '/login/', login)
            .then((response) => {
                updateHeaders({
                    Authorization: `Token ${response.data.token}`,
                })
                let data = response.data
                if (varValue) {
                    data.is_app = varValue === APP_TOKEN
                    cookies.set('t_', varValue, { path: '/' })
                }
                cookies.set('token', data.token, { path: '/' })
                cookies.set('uid', data.uid, { path: '/' })
                cookies.set('hotel', data.hotel, { path: '/' })
                cookies.set('type', data.type, { path: '/' })
                cookies.set('username', data.username, { path: '/' })
                props.updateContextVal({
                    ...data,
                    isLogged: true,
                })
            })
            .catch((err) => {
                setTimeout(() => {
                    setLoading(false)
                    setError(true)
                }, 1000)
            })
    }

    const InitLogin = async () => {
        let token = cookies.get('token')
        let varValue = cookies.get('t_')
        let response = await axios.post(
            SERVER_URL + '/validateToken/',
            { token: token },
            { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
        )

        let data = response.data
        if (varValue) {
            data.is_app = varValue === APP_TOKEN
            cookies.set('t_', varValue, { path: '/' })
        }
        updateHeaders({
            Authorization: `Token ${data.token}`,
        })
        cookies.set('hotel', data.hotel, { path: '/' })
        props.updateContextVal({
            ...data,
            isLogged: true,
        })
    }

    React.useEffect(() => {
        InitLogin()
    }, [])

    const _handleShow = () => {
        setShow(!show)
    }

    return (
        <FlexContainer>
            <Container fluid>
                <Row>
                    <Col md={{ size: 4, offset: 4 }} sm="12" xs="12">
                        <FormLogin>
                            <Row>
                                <Col>
                                    <div className="logo-initial text-center">
                                        <img src="https://dashboard.puntacanaapp.com.do/static/media/logo-puntacanaapp.52a33f2d.png" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Field
                                        placeholder="Totem"
                                        type="username"
                                        className="field-login"
                                        name="username"
                                        id="username"
                                        onChange={handleChangeField}
                                        value={login.username}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Field
                                        style={{ paddingRight: '30px' }}
                                        placeholder="Contraseña"
                                        type={show ? 'text' : 'password'}
                                        name="password"
                                        id="password"
                                        onChange={handleChangeField}
                                        value={login.password}
                                    />
                                    <SpanEye onClick={_handleShow}>
                                        {show ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}
                                    </SpanEye>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {!loading ? (
                                        <ButtonSubmit onClick={Login} className="btn-large waves-button-input">
                                            Iniciar sesión
                                        </ButtonSubmit>
                                    ) : (
                                        <ButtonSubmit className="btn-large waves-button-input">
                                            <Spinner size={20} />
                                        </ButtonSubmit>
                                    )}
                                </Col>
                            </Row>
                            {error && (
                                <Row>
                                    <Col>
                                        <Danger>Datos de acceso invalidos</Danger>
                                    </Col>
                                </Row>
                            )}
                        </FormLogin>
                    </Col>
                </Row>
            </Container>
        </FlexContainer>
    )
}

export default withContext(Login)

const SpanEye = styled.span`
    position: absolute;
    float: right;
    right: 20px;
    top: 15px;
    color: #555;
    cursor: pointer;
    transition: all 0.3s ease;
    :hover {
        color: #777;
    }
`

const FlexContainer = styled.div`
    display: flex;
    width: 100%;
    min-height: 90vh;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
`

const Danger = styled.small`
    color: #f00;
`

const FormLogin = styled.form`
    padding: 20px 15px;
    width: 100%;
    flex-grow: 1;
    box-shadow: 0 3px 5px #ccc;
    .row {
        margin-bottom: 15px;
    }
`

const ButtonSubmit = styled.button`
    cursor: pointer;
    background-color: #00a656;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    transition: all 0.3s ease;
    font-size: 14px;
    :hover {
        opacity: 0.9;
    }
`

const Field = styled.input`
    padding: 10px 15px;
    font-size: 14px;
    border: solid 1px #ccc;
    border-radius: 3px;
    width: 100%;
`
