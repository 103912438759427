import React from 'react'
import styled from 'styled-components'
import { Modal, ModalBody } from 'reactstrap'
import { withContext } from '../../context/withContext'
function ChangeLanguage(props) {
    return (
        <Modal isOpen={props.modal} toggle={props.toggle} style={{ borderColor: 'transparent' }}>
            <ModalBody>
                <Languages>
                    <li
                        onClick={() => {
                            props.updateContextVal({ lang: 'spanish' })
                            props.toggle()
                        }}>
                        <div id="flag">
                            <img src="https://flagpedia.net/data/flags/h80/es.png" />
                        </div>{' '}
                        <div className="d-inline">Español</div>
                    </li>
                    <li
                        onClick={() => {
                            props.updateContextVal({ lang: 'english' })
                            props.toggle()
                        }}>
                        <div id="flag">
                            <img src="https://flagpedia.net/data/flags/h80/us.png" />
                        </div>{' '}
                        <div className="d-inline">English</div>
                    </li>
                    <li
                        onClick={() => {
                            props.updateContextVal({ lang: 'german' })
                            props.toggle()
                        }}>
                        <div id="flag">
                            <img src="https://flagpedia.net/data/flags/h80/de.png" />
                        </div>{' '}
                        <div className="d-inline">German</div>
                    </li>
                    <li
                        onClick={() => {
                            props.updateContextVal({ lang: 'french' })
                            props.toggle()
                        }}>
                        <div id="flag">
                            <img src="https://flagpedia.net/data/flags/h80/fr.png" />
                        </div>{' '}
                        <div className="d-inline">French</div>
                    </li>
                    <li
                        onClick={() => {
                            props.updateContextVal({ lang: 'russian' })
                            props.toggle()
                        }}>
                        <div id="flag">
                            <img src="https://flagpedia.net/data/flags/h80/ru.png" />
                        </div>{' '}
                        <div className="d-inline">Russian</div>
                    </li>
                </Languages>
            </ModalBody>
        </Modal>
    )
}
const Languages = styled.ul`
    margin: 0;
    padding: 0;
    li:last-child {
        margin-bottom: 0;
    }
    li {
        padding: 1rem;
        border: 1px solid #eee;
        border-radius: 2px;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        background: #fff;
        img {
            margin-right: 2rem;
            display: inline-block;
        }
        margin-bottom: 1rem;
        :active {
            background: #2d86c0;
            color: #fff;
        }
    }
    .d-inline {
        padding-left: 1.4rem !important;
    }
    #flag {
        width: 100px;
        height: 50px;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 4px;
        }
    }
`
export default withContext(ChangeLanguage)
