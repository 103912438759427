import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { Events } from '../../utils/demo'
import EventCard from '../events/EventCard'
import { FilterExcursions } from '../../api/excursions'
import { Col, Row } from 'reactstrap'

export default function RelatedExcursions(props) {
    const [excursions, setExcursions] = useState([])

    useEffect(() => {
        let category =
            typeof props.excursion.category === 'object' ? props.excursion.category.id : props.excursion.category
        if (category) {
            FilterExcursions(`?is_featured=1&is_active=1&current=${props.excursion.uid}`).then((response) => {
                setExcursions(response.data)
            })
        }
    }, [props.excursion])

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
    }
    return (
        <FooterContainer>
            {excursions.length > 4 ? (
                <Slider {...settings}>
                    {excursions.map((el, key) => (
                        <div key={key}>
                            <EventCard enableLink small event={el} />
                        </div>
                    ))}
                </Slider>
            ) : (
                <Row>
                    {excursions.map((el, key) => (
                        <Col xs={3}>
                            <div key={key}>
                                <EventCard enableLink small event={el} />
                            </div>
                        </Col>
                    ))}
                </Row>
            )}
        </FooterContainer>
    )
}

const FooterContainer = styled.div`
    width: 100%;
`
