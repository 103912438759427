import React from 'react'
import styled, { css } from 'styled-components'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { withContext } from '../../context/withContext'
import { Mixpanel } from '../../utils/mixpanel'

function Purchase(props) {
    React.useEffect(() => {
        Mixpanel.identify(props.context.token)
        Mixpanel.people.set({ $first_name: props.context.username })
    }, [])

    const TrackAction = () => {
        Mixpanel.track('Clic en botón RESERVAR AHORA.')
    }

    return (
        <Container>
            <Row>
                <Col xs={4}>
                    <GotoButton active={props.buttonActive === 1} onClick={() => props.onClick(1)}>
                        <i className="fab fa-youtube"></i>
                        {props.context.lang === 'spanish' && 'Ver Video'}
                        {props.context.lang === 'english' && 'Watch video'}
                        {props.context.lang === 'german' && 'Schau Video'}
                        {props.context.lang === 'french' && 'Voir vidéo'}
                        {props.context.lang === 'russian' && 'Смотреть видео'}
                    </GotoButton>
                    <GotoButton active={props.buttonActive === 2} onClick={() => props.onClick(2)}>
                        <i className="fas fa-map-marker-alt"></i>
                        {props.context.lang === 'spanish' && 'Ver Ubicación'}
                        {props.context.lang === 'english' && 'View Location'}
                        {props.context.lang === 'german' && 'Standort anzeigenLese weniger'}
                        {props.context.lang === 'french' && "Afficher l'emplacement"}
                        {props.context.lang === 'russian' && 'Посмотреть местоположение'}
                    </GotoButton>
                </Col>
                {typeof props.excursion !== 'undefined' && props.excursion.can_purchase && (
                    <Col xs={4}>
                        <Link to={`/detail/${props.excursion.id}/order`} onClick={TrackAction}>
                            <BookNowBtn>
                                <span className="big-span">
                                    {props.context.lang === 'spanish' && 'RESERVAR'}
                                    {props.context.lang === 'english' && 'BOOKING'}
                                    {props.context.lang === 'german' && 'RESERVIEREN'}
                                    {props.context.lang === 'french' && 'RÉSERVEZ'}
                                    {props.context.lang === 'russian' && 'ЗАБРОНИРОВАТЬ'}
                                </span>
                                <span className="small-span">
                                    {props.context.lang === 'spanish' && 'AHORA'}
                                    {props.context.lang === 'english' && 'NOW'}
                                    {props.context.lang === 'german' && 'RESERVIEREN'}
                                    {props.context.lang === 'french' && 'MAINTENANT'}
                                    {props.context.lang === 'russian' && 'СЕЙЧАС'}
                                </span>
                            </BookNowBtn>
                        </Link>
                    </Col>
                )}
                <Col xs={4}></Col>
            </Row>
        </Container>
    )
}
const BookNowBtn = styled.div`
    background: #8acd00;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;

    .big-span,
    .small-span {
        display: block;
        font-size: 2rem;
        font-weight: 800;
    }
    .big-span {
        font-size: 32px;
        letter-spacing: 0px;
    }
    .small-span {
        font-weight: 800;
        font-size: 22px;
        margin-top: -10px;
        letter-spacing: 5px;
    }
    :hover,
    :active {
        opacity: 0.7;
    }
`
const Container = styled.div`
    position: fixed;
    z-index: 9;
    bottom: 135px;
    left: 0;
    width: 100%;
    padding-bottom: 10px;
    box-sizing: border-box;
    background: #fff;
    border-top: 3px solid #eee;
    padding: 1rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 17%,
        rgba(228, 228, 228, 1) 37%,
        rgba(255, 255, 255, 1) 58%,
        rgba(255, 255, 255, 1) 100%
    );
    .flex-center img {
        :active {
            opacity: 0.7;
        }
    }
    h4 {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 900;
        color: rgb(19, 124, 189);
    }
    a,
    a:active,
    a:hover {
        text-decoration: none;
        opacity: 0.7;
    }
`
const GotoButton = styled.button`
    background: #fff;
    border: 2px solid #eee;
    border-radius: 4px;
    padding: 0.4rem;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
    i {
        width: 30px;
        margin-right: 5px;
        color: rgb(19, 124, 189);
    }
    :active {
        background: rgb(19, 124, 189);
        border-color: rgb(19, 124, 189);
        color: #fff;
        i {
            color: #fff;
        }
    }
    ${(props) =>
        props.active &&
        css`
            color: rgb(19, 124, 189);
        `}
`
export default withContext(Purchase)
