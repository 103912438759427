import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { withContext } from '../../context/withContext'
import renderHTML from 'react-render-html'
// local import
import Footer from '../../Layouts/Footer'
import Cart from '../../components/events/Cart'
import { GetReservation } from '../../api/reservations'
import FeatherIcon from 'feather-icons-react'
import Cookies from 'universal-cookie'
import QRCode from 'qrcode.react'
const cookies = new Cookies()
const token = cookies.get('token')

function ThanYouPage(props) {
    const [data, setData] = useState(null)
    const [showExcursions, handleExcursions] = useState(false)
    const [categoryKey, setKey] = useState(null)

    useEffect(() => {
        const uid = props.match.params.uid
        GetReservation(uid, token).then((response) => {
            setData(response.data)
        })
    }, [])

    const routeQR = () => {
        let url = `${window.location.host}${props.location.pathname}`
        return url
    }

    const calculatePrice = (amount) => {
        let rate = props.context.exchange_rate
        if (rate > 0) {
            return amount * rate
        } else {
            return amount
        }
    }

    return (
        <Container>
            <div className="container-fluid" style={{ paddingBottom: '10rem' }}>
                <Row>
                    <Col xs={12} className="text-center">
                        <Title>
                            {props.context.lang === 'spanish' && 'Gracias!'}
                            {props.context.lang === 'english' && 'Thank you!'}
                            {props.context.lang === 'german' && 'Danke dir!'}
                            {props.context.lang === 'french' && 'Je vous remercie!'}
                            {props.context.lang === 'russian' && 'Спасибо!'}
                        </Title>
                    </Col>
                    <Col xs={12} className="text-center" style={{ minHeigth: '150px;' }}>
                        <ContentIcon>
                            <FeatherIcon icon="check" size="100px" stroke="#00AD5A" />
                        </ContentIcon>
                    </Col>
                    <Col xs={12} className="text-center">
                        <SubTitle>
                            {props.context.lang === 'spanish' && 'Esperamos que disfrutes de esta experiencia.'}
                            {props.context.lang === 'english' && 'We hope you enjoy this experience.'}
                            {props.context.lang === 'german' && 'Wir hoffen, Sie genießen diese Erfahrung.'}
                            {props.context.lang === 'french' && 'Nous espérons que vous apprécierez cette expérience.'}
                            {props.context.lang === 'russian' && 'Надеемся, вам понравится этот опыт.'}
                        </SubTitle>
                    </Col>
                </Row>
                {data !== null && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col xs={{ size: 4, offset: 4 }}>
                            <Item>
                                {props.context.lang === 'spanish' && 'Nombre:'}
                                {props.context.lang === 'english' && 'Name:'}
                                {props.context.lang === 'german' && 'Name:'}
                                {props.context.lang === 'french' && 'Nom:'}
                                {props.context.lang === 'russian' && 'Имя:'} <strong>{data.full_name}</strong>
                            </Item>
                        </Col>
                        <Col xs={{ size: 4, offset: 4 }}>
                            <Item>
                                {props.context.lang === 'spanish' && 'Nro. de orden:'}
                                {props.context.lang === 'english' && 'Order number:'}
                                {props.context.lang === 'german' && 'Bestellnummer:'}
                                {props.context.lang === 'french' && 'Numéro de commande:'}
                                {props.context.lang === 'russian' && 'Порядковый номер:'} # <strong>{data.uid}</strong>
                            </Item>
                        </Col>
                        <Col xs={{ size: 4, offset: 4 }}>
                            <Item>
                                {props.context.lang === 'spanish' && 'Excursión:'}
                                {props.context.lang === 'english' && 'Excursion:'}
                                {props.context.lang === 'german' && 'Ausflug:'}
                                {props.context.lang === 'french' && 'Excursion:'}
                                {props.context.lang === 'russian' && 'Экскурсия:'}{' '}
                                <strong>
                                    {props.context.lang === 'spanish' && data.excursion.name}
                                    {props.context.lang === 'english' && data.excursion.name_en}
                                    {props.context.lang === 'german' && data.excursion.name_gr}
                                    {props.context.lang === 'french' && data.excursion.name_fr}
                                    {props.context.lang === 'russian' && data.excursion.name_ru}{' '}
                                </strong>
                            </Item>
                        </Col>
                        <Col xs={{ size: 4, offset: 4 }}>
                            <Item>
                                {props.context.lang === 'spanish' && 'Idioma:'}
                                {props.context.lang === 'english' && 'Language:'}
                                {props.context.lang === 'german' && 'Sprache:'}
                                {props.context.lang === 'french' && 'langage:'}
                                {props.context.lang === 'russian' && 'Язык:'}{' '}
                                <strong>
                                    {data.lang_tour === '1' && 'Español'}
                                    {data.lang_tour === '2' && 'English'}
                                    {data.lang_tour === '3' && 'Deutsche'}
                                    {data.lang_tour === '4' && 'Français'}
                                    {data.lang_tour === '5' && 'Русский'}
                                </strong>
                            </Item>
                        </Col>
                        <Col xs={{ size: 4, offset: 4 }}>
                            <Item>
                                {props.context.lang === 'spanish' && 'Total:'}
                                {props.context.lang === 'english' && 'Total:'}
                                {props.context.lang === 'german' && 'Gesamt:'}
                                {props.context.lang === 'french' && 'Total:'}
                                {props.context.lang === 'russian' && 'Общее количество:'}{' '}
                                <strong>
                                    {calculatePrice(data.total).toFixed(2)}
                                    {props.context.exchange_rate > 0 ? ' RD$' : ' US$'}
                                </strong>
                            </Item>
                        </Col>
                        <Col xs={{ size: 4, offset: 4 }} className="text-center" style={{ marginTop: '20px' }}>
                            <QRCode value={routeQR()} />
                        </Col>
                    </Row>
                )}
            </div>
            <Footer showBack showBackFunc={() => props.history.push('/')} />
        </Container>
    )
}
const Container = styled.div`
    font-size: 0.8rem;
    margin-top: 1.5rem;
    h4 {
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 1px solid #eee;
        margin-bottom: 1.5rem;
        padding-bottom: 10px;
        color: rgb(19, 124, 189);
    }
    .container-fluid {
        img {
            border-radius: 10px;
            box-shadow: 0px 0px 10px #00000045;
            margin-bottom: 1.5rem;
        }
    }
`

export default withRouter(withContext(ThanYouPage))

const Title = styled.h1`
    font-size: 58px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 50px;
`
const SubTitle = styled.h2`
    font-size: 18px;
    font-weight: 400;
`
const ContentIcon = styled.div`
    text-align: center;
    border: solid 3px #00ad5a;
    border-radius: 50%;
    padding: 20px;
    max-width: 180px;
    position: relative;
    float: left;
    left: calc(50% - 80px);
    margin-top: 15px;
    margin-bottom: 20px;
`

const Item = styled.div`
    margin-bottom: 10px;
    border-radius: 3px;
    background-color: #f1f1f1;
    padding: 5px 10px;
`
