import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { Fade } from 'react-reveal'
// Local import
import { FilterExcursions } from '../../api/excursions'
import EventCard from '../../components/events/EventCard'
import Footer from '../../Layouts/Footer'
import AppLoader from '../../components/AppLoader'
import { withContext } from '../../context/withContext'
import EventCardRow from '../../components/events/EventCardRow'
import { Mixpanel } from '../../utils/mixpanel'

function Home(props) {
    const [showExcursions, handleExcursions] = useState(false)
    const [loading, setLoader] = useState(true)
    const [categoryKey, setKey] = useState(null)
    const [categories, setCategories] = useState([])
    const [excursions, setExcursions] = useState([])

    useEffect(() => {
        if (props.context.categories.length > 0) {
            setCategories(props.context.categories)
            setLoader(false)
        }
        Mixpanel.identify(props.context.token)
        Mixpanel.people.set({ $first_name: props.context.username })
    }, [props.context.categories])

    const handleCategory = async (query, key) => {
        setKey(key)
        let response = await FilterExcursions(`?category=${query}&hotel=${props.context.hotel}`)

        setExcursions(response.data)
        handleExcursions(true)

        Mixpanel.track(`Clic en Categoría. ${categories[key].name}`, categories[key])
    }

    const onClickExcursion = (excursion, key) => {
        Mixpanel.track(`Clic en Excursión ${excursion.name}.`, excursion)
    }

    return (
        <>
            <Container>
                {loading ? (
                    <AppLoader />
                ) : (
                    <Row>
                        <Col xs={12}>
                            <Row style={{ marginTop: '1rem' }}>
                                <Col xs={4}>
                                    {showExcursions && (
                                        <span id="goBack" onClick={() => handleExcursions(false)}>
                                            {props.context.lang === 'spanish' && '← Volver atrás'}
                                            {props.context.lang === 'english' && '← Go back'}
                                            {props.context.lang === 'german' && '← Geh zurück'}
                                            {props.context.lang === 'french' && '← Retourner en arrière'}
                                            {props.context.lang === 'russian' && '← Вернитесь назад'}
                                        </span>
                                    )}
                                </Col>
                                <Col xs={4}>
                                    <center>
                                        <h4>
                                            {showExcursions ? (
                                                <span>
                                                    {props.context.lang === 'spanish' && categories[categoryKey].name}
                                                    {props.context.lang === 'english' &&
                                                        categories[categoryKey].name_en}
                                                    {props.context.lang === 'german' && categories[categoryKey].name_gr}
                                                    {props.context.lang === 'french' && categories[categoryKey].name_fr}
                                                    {props.context.lang === 'russian' &&
                                                        categories[categoryKey].name_ru}
                                                </span>
                                            ) : (
                                                <span>
                                                    {props.context.lang === 'spanish' && 'Categorías'}
                                                    {props.context.lang === 'english' && 'Categories'}
                                                    {props.context.lang === 'german' && 'Kategorien'}
                                                    {props.context.lang === 'french' && 'Catégories'}
                                                    {props.context.lang === 'russian' && 'Категории'}
                                                </span>
                                            )}
                                        </h4>
                                    </center>
                                </Col>
                                <Col xs={4}></Col>
                            </Row>
                        </Col>
                        {showExcursions
                            ? excursions.map((e, key) => (
                                  <Col
                                      key={key}
                                      xs={categories[categoryKey].display_template === '1' ? 4 : 12}
                                      className="mb-3 px-3">
                                      {/* <Fade right> */}
                                      {categories[categoryKey].display_template === '1' ? (
                                          <EventCard event={e} enableLink onClick={() => onClickExcursion(e, key)} />
                                      ) : (
                                          <EventCardRow event={e} />
                                      )}
                                      {/* </Fade> */}
                                  </Col>
                              ))
                            : categories.map((e, key) => (
                                  <Col key={key} xs="4" className="mb-3 px-3">
                                      <Fade bottom>
                                          <EventCard event={e} onClick={() => handleCategory(e.id, key)} />
                                      </Fade>
                                  </Col>
                              ))}
                    </Row>
                )}
            </Container>
            <Footer
                handleHotel={() => handleCategory(null, 0)}
                showBack={showExcursions}
                showBackFunc={() => handleExcursions(false)}
            />
        </>
    )
}
const Container = styled.div`
    position: relative;
    margin-bottom: 250px;
    padding: 10px;
    h4 {
        padding-bottom: 1rem;
        text-transform: uppercase;
    }
    #goBack {
        cursor: pointer;
        font-size: 1rem;
    }
`
export default withContext(Home)
