import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { withContext } from '../../context/withContext'
import renderHTML from 'react-render-html'
// local import
import Footer from '../../Layouts/Footer'
import Cart from '../../components/events/Cart'
import { GetExcursion } from '../../api/excursions'

function Order(props) {
    const [handler, mapHandler] = useState(1)
    const [data, setData] = useState(null)
    const [loading, setLoader] = useState(true)
    useEffect(() => {
        const uid = props.match.params.uid
        GetExcursion(uid).then((response) => {
            setLoader(false)
            setData(response.data)
        })
    }, [])
    return (
        <Container>
            <div className="container-fluid" style={{ paddingBottom: '10rem' }}>
                <Row>
                    <Col xs={12}>
                        <img src={data !== null && data.big_image} width="100%" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} style={{ borderRight: '2px solid #eee' }}>
                        <div className="card-body" style={{ paddingTop: 0 }}>
                            {data !== null && data.description && (
                                <div>
                                    {props.context.lang === 'spanish' && (
                                        <h4>Acerca de{data.excursion_type === '1' ? ' la excursión' : ':'}</h4>
                                    )}
                                    {props.context.lang === 'english' && (
                                        <h4>About {data.excursion_type === '1' ? ' the excursion' : ':'}</h4>
                                    )}
                                    {props.context.lang === 'german' && (
                                        <h4>Über den{data.excursion_type === '1' ? ' Ausflug' : ':'}</h4>
                                    )}
                                    {props.context.lang === 'french' && (
                                        <h4>À propos de{data.excursion_type === '1' ? " l'excursion" : ':'}</h4>
                                    )}
                                    {props.context.lang === 'russian' && (
                                        <h4>Об{data.excursion_type === '1' ? ' экскурсии' : ':'}</h4>
                                    )}

                                    {props.context.lang === 'spanish' &&
                                        data.description &&
                                        renderHTML(data.description)}
                                    {props.context.lang === 'english' &&
                                        data.description_en &&
                                        renderHTML(data.description_en)}
                                    {props.context.lang === 'german' &&
                                        data.description_gr &&
                                        renderHTML(data.description_gr)}
                                    {props.context.lang === 'french' &&
                                        data.description_fr &&
                                        renderHTML(data.description_fr)}
                                    {props.context.lang === 'russian' &&
                                        data.description_ru &&
                                        renderHTML(data.description_ru)}
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col xs={6}>
                        <CartController>
                            <Cart excursion={data} />
                        </CartController>
                    </Col>
                </Row>
            </div>
            <Footer showBack excursion={data} />
        </Container>
    )
}
const Container = styled.div`
    font-size: 0.8rem;
    margin-top: 1.5rem;
    h4 {
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 1px solid #eee;
        margin-bottom: 1.5rem;
        padding-bottom: 10px;
        color: rgb(19, 124, 189);
    }
    .container-fluid {
        img {
            border-radius: 10px;
            box-shadow: 0px 0px 10px #00000045;
            margin-bottom: 1.5rem;
        }
    }
    /* ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    ul li i {
        margin-right: 5px;
        color: green;
    } */
`
const CartController = styled.div`
    .CalendarDay {
        font-size: 1.2rem;
    }
    .DateInput {
        display: none;
    }
`
export default withRouter(withContext(Order))
