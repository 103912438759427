import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { GetSliders } from '../api/advertisement'
import { withContext } from '../context/withContext'
import FeatherIcon from 'feather-icons-react'

const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    fade: true,
    pauseOnHover: false,
    nextArrow: <span style={{ display: 'none !important' }} />,
    prevArrow: <span style={{ display: 'none !important' }} />,
}

function Advertisement(props) {
    const [sliders, setSlider] = React.useState([])
    const [current, setCurrent] = React.useState(0)
    const [muted, setMuted] = React.useState(true)
    const refslider = React.useRef(null)

    const GetSliders_ = async () => {
        let response = await GetSliders(`hotel=${props.context.hotel}`)
        setSlider(response.data)
        let sliders_ = response.data
        let change = 0
        let current_ = 0

        setInterval(() => {
            change++
            if (change >= sliders_[current_].duration) {
                refslider.current.slickNext()
                change = 0
                current_ = current_ + 1
                if (current_ > sliders_.length - 1) {
                    current_ = 0
                }
                setCurrent(current_)
            }
        }, 1000)
    }

    React.useEffect(() => {
        GetSliders_()
    }, [])

    return (
        <Container>
            <Slider ref={refslider} {...settings}>
                {sliders.map((e, key) =>
                    e.type === 'image'
                        ? current === key && <Item key={key} bg={e.file} />
                        : current === key && (
                              <>
                                  <Video
                                      playsInline
                                      id="video_advertisements"
                                      autoPlay={true}
                                      src={e.file}
                                      key={key}
                                      muted={muted}
                                  />
                                  <HandleAudio
                                      onClick={() => {
                                          document.getElementById('video_advertisements').muted = !muted
                                          setMuted(!muted)
                                      }}>
                                      <FeatherIcon icon="volume-2" size="52" stroke="#fff" />
                                  </HandleAudio>
                              </>
                          )
                )}
            </Slider>
        </Container>
    )
}

export default withContext(Advertisement)

const Container = styled.div`
    background-color: #000;
    text-align: center;
`

const Video = styled.video`
    height: 100vh;
`

const HandleAudio = styled.button`
    position: absolute;
    float: right;
    right: 10px;
    bottom: 10px;
    border: none;
    background: none;
    box-shadow: none;
    cursor: pointer;
    :focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
`

const Item = styled.div`
    /* width: 100%; */
    text-align: center;
    height: 100vh !important;
    background: url(${(props) => props.bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`
