import axios from 'axios'
export const APP_TOKEN =
    'ewoibmFtZToibGVkY29tbWVyY2UiLAoidHlwZSI6InN0b3JlIgoiY29uZmlnIjoiYXBwIgoicGF5bWVudCI6IlBoeXNpY2FsIFBPUyIKfQ=='
export const baseURL =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? // ? 'http://localhost:8000/api/v1'
          'https://secure.puntacanaapp.com.do/api/v1'
        : 'https://secure.puntacanaapp.com.do/api/v1'

export const API = axios.create({
    baseURL: baseURL,
})

export function updateHeaders(headers) {
    let _headersName = Object.keys(headers)
    for (let i in _headersName) {
        API.defaults.headers.common[_headersName[i]] = headers[_headersName[i]]
    }
}
