import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@blueprintjs/core'

export default function AppLoader(props) {
    return (
        <>
            <SpinnerContainer>
                <Spinner size={100} intent="primary" />
            </SpinnerContainer>
        </>
    )
}
const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 60vh;
    opacity: 0.5;
`
