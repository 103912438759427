import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { withContext } from '../../context/withContext'
import renderHTML from 'react-render-html'
// local import
import Footer from '../../Layouts/Footer'
import Cart from '../../components/events/Cart'
import { GetReservation } from '../../api/reservations'
import FeatherIcon from 'feather-icons-react'
import Cookies from 'universal-cookie'
import QRCode from 'qrcode.react'
import DeclinedImg from '../../static/images/payment_declined.png'
const cookies = new Cookies()
const token = cookies.get('token')

function ThanYouPage(props) {
    return (
        <Container>
            <div className="container-fluid" style={{ paddingBottom: '10rem' }}>
                <Row>
                    <Col md={{ offset: 1, size: 10 }} className="text-center">
                        <Img width="50%" src={DeclinedImg} />
                        <Title>
                            {props.context.lang === 'spanish' && 'PAGO RECHAZADO'}
                            {props.context.lang === 'english' && 'PAYMENT DECLINED'}
                            {props.context.lang === 'german' && 'ZAHLUNG ABGELEHNT'}
                            {props.context.lang === 'french' && 'PAIEMENT REFUSÉ'}
                            {props.context.lang === 'russian' && 'ПЛАТЕЖ ОТКЛОНЕН'}
                        </Title>
                        <SubTitle className="text-center">
                            {props.context.lang === 'spanish' &&
                                ' Lo sentimos su pago no pudo ser procesado, por favor intenta mas tarde o prueba realizar el pago con otra tarjeta.'}
                            {props.context.lang === 'english' &&
                                'We are sorry your payment could not be processed, please try again later or try to make the payment with another card.'}
                            {props.context.lang === 'german' &&
                                'Es tut uns leid, dass Ihre Zahlung nicht verarbeitet werden konnte. Bitte versuchen Sie es später erneut oder versuchen Sie, die Zahlung mit einer anderen Karte vorzunehmen.'}
                            {props.context.lang === 'french' &&
                                "Nous sommes désolés que votre paiement n'ait pas pu être traité, veuillez réessayer plus tard ou essayez d'effectuer le paiement avec une autre carte."}
                            {props.context.lang === 'russian' &&
                                'Сожалеем, что ваш платеж не может быть обработан. Повторите попытку позже или попробуйте произвести оплату с помощью другой карты.'}
                        </SubTitle>
                    </Col>
                </Row>
            </div>
            <Footer showBack showBackFunc={() => props.history.push('/')} />
        </Container>
    )
}
const Container = styled.div`
    font-size: 0.8rem;
    margin-top: 1.5rem;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    h4 {
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom: 1px solid #eee;
        margin-bottom: 1.5rem;
        padding-bottom: 10px;
        color: rgb(19, 124, 189);
    }
    .container-fluid {
        img {
            border-radius: 10px;
            box-shadow: 0px 0px 10px #00000045;
            margin-bottom: 25px;
        }
    }
`

export default withRouter(withContext(ThanYouPage))

const Img = styled.img`
    box-shadow: none !important;
`

const Title = styled.h1`
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 25px;

    color: #ff7777;
`
const SubTitle = styled.h2`
    font-size: 18px;
    font-weight: 400;
`
