import React from 'react'
import styled from 'styled-components'
import Footer from '../../Layouts/Footer'

function Map(props) {
    return (
        <Container>
            {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15136.784039903774!2d-68.4028188!3d18.4747786!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xced4aebd00b3e907!2sHyatt%20Zilara%20Capcana!5e0!3m2!1ses!2sdo!4v1577057474088!5m2!1ses!2sdo"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""></iframe> */}
            <Footer showBack />
        </Container>
    )
}
const Container = styled.div`
    height: 90vh;
`
export default Map
