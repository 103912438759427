import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { withContext } from '../../context/withContext'
function EventCard(props) {
    return (
        <Container small={props.small}>
            {props.enableLink ? (
                <Link to={`/detail/${props.event.id}`} onClick={props.onClick}>
                    <img src={props.event.featured_image} />
                    <Name small={props.small}>
                        <div id="eventName">
                            {props.context.lang === 'spanish' && props.event.name && props.event.name}
                            {props.context.lang === 'english' && props.event.name_en && props.event.name_en}
                            {props.context.lang === 'german' && props.event.name_gr && props.event.name_gr}
                            {props.context.lang === 'french' && props.event.name_fr && props.event.name_fr}
                            {props.context.lang === 'russian' && props.event.name_ru && props.event.name_ru}
                        </div>
                    </Name>
                </Link>
            ) : (
                <div onClick={props.onClick}>
                    <img src={props.event.featured_image} />
                    <Name small={props.small}>
                        <div id="eventName">
                            {props.context.lang === 'spanish' && props.event.name && props.event.name}
                            {props.context.lang === 'english' && props.event.name_en && props.event.name_en}
                            {props.context.lang === 'german' && props.event.name_gr && props.event.name_gr}
                            {props.context.lang === 'french' && props.event.name_fr && props.event.name_fr}
                            {props.context.lang === 'russian' && props.event.name_ru && props.event.name_ru}
                        </div>
                    </Name>
                </div>
            )}
        </Container>
    )
}
const Container = styled.div`
    position: relative;
    border-radius: 8px;
    box-shadow: 0px 0px 10px #0000002e;
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid #eee;
    }
    ${(props) =>
        props.small &&
        css`
            padding: 1rem;
            width: 100%;
            box-shadow: none;
            margin-bottom: 3rem;
            img {
                height: 150px;
            }
        `}
`
const Name = styled.div`
    position: absolute;
    bottom: -1.5rem;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 1rem;
    #eventName {
        background: #fffffff0;
        padding: 0.8rem;
        border-radius: 4px;
        border: 1px solid #d6d6d6;
        /* text-overflow: ellipsis; */
        /* overflow: hidden; */
        /* white-space: nowrap; */
    }
    ${(props) =>
        props.small &&
        css`
            bottom: 0;
            padding: 1rem;
            left: 0;
            top: 74%;
        `}
`
export default withContext(EventCard)
