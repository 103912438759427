import React from 'react'
import styled from 'styled-components'
import { Modal, ModalBody } from 'reactstrap'

function Name(props) {
    return (
        <Modal isOpen={props.modal} toggle={props.toggle} style={{ borderColor: 'transparent' }}>
            <ModalBody>
                {props.modal && (
                    <iframe
                        src="https://www.hyatt.com/es-ES/hotel/dominican-republic/hyatt-zilara-cap-cana/pujia"
                        width="100%"
                        height="80vh"
                    />
                )}
            </ModalBody>
        </Modal>
    )
}
export default Name
