import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import renderHTML from 'react-render-html'
import NumberFormat from 'react-number-format'

function OperatorCard(props) {
    const operator = props.operator
    return (
        <Container>
            <div>
                <Row>
                    <Col xs={8}>
                        <div className="media">
                            <img className="mr-3" src={operator.logo} alt={operator.name} />
                            <div className="media-body">
                                <h5 className="mt-0">{operator.name}</h5>
                                <div className="description">
                                    {operator.description && renderHTML(operator.description)}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={4}>
                        <div className="flex-end">
                            <ul>
                                <li>
                                    <i className="fas fa-phone"></i>{' '}
                                    <NumberFormat displayType="text" value={operator.phone1} format="(###) ###-####" />
                                </li>
                                <li>
                                    <i className="fas fa-phone"></i>{' '}
                                    <NumberFormat displayType="text" value={operator.phone2} format="(###) ###-####" />
                                </li>
                                <li>
                                    <i className="far fa-envelope"></i> {operator.email}
                                </li>
                                <li>
                                    <i className="fas fa-globe"></i> {operator.website}
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}
const Container = styled.div`
    .description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 50px;
    }
`
export default OperatorCard
