import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import FeatherIcon from 'feather-icons-react'

const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    fade: true,
    pauseOnHover: false,
    nextArrow: <span style={{ display: 'none !important' }} />,
    prevArrow: <span style={{ display: 'none !important' }} />,
}

export default function Screensaver({ screensavers }) {
    const [current, setCurrent] = useState(0)
    const [muted, setMuted] = useState(true)
    const refSlider = useRef(null)
    const videoRef = useRef(null)
    let timer = useRef(null)

    useEffect(() => {
        if (screensavers.length > 0) {
            startSlider()
        }

        return () => {
            clearTimeout(timer.current) // Limpia el temporizador al desmontar
        }
    }, [current])

    const startSlider = () => {
        const currentSlide = screensavers[current]

        if (currentSlide.type === 'image') {
            // Manejo para imágenes con duración fija
            timer.current = setTimeout(() => {
                moveToNextSlide()
            }, 8000) // Duración fija de 8 segundos para imágenes
        }
    }

    const moveToNextSlide = () => {
        clearTimeout(timer.current)
        const nextIndex = (current + 1) % screensavers.length // Reinicia al llegar al final
        setCurrent(nextIndex)
        refSlider.current.slickNext()
    }

    const handleVideoEnd = () => {
        moveToNextSlide() // Avanza automáticamente cuando el video termina
    }

    const toggleMute = () => {
        setMuted((prevMuted) => !prevMuted)
        if (videoRef.current) {
            videoRef.current.muted = !muted
        }
    }

    return (
        <ScreensaverContainer>
            <Slider ref={refSlider} {...settings}>
                {screensavers.map((e, key) =>
                    e.type === 'image' ? (
                        <Item key={key} bg={e.file} active={current === key} />
                    ) : (
                        current === key && (
                            <React.Fragment key={key}>
                                <Video
                                    ref={videoRef}
                                    playsInline
                                    autoPlay
                                    muted={muted}
                                    src={e.file}
                                    onEnded={handleVideoEnd} // Avanza automáticamente cuando el video termina
                                />
                                <HandleAudio onClick={toggleMute}>
                                    <FeatherIcon icon={muted ? 'volume-x' : 'volume-2'} size="52" stroke="#fff" />
                                </HandleAudio>
                            </React.Fragment>
                        )
                    )
                )}
            </Slider>
        </ScreensaverContainer>
    )
}

const ScreensaverContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #fff;
    img {
        object-fit: cover;
    }
    .slick-arrow {
        display: none !important;
    }
`

const Video = styled.video`
    height: 100vh;
    width: 100%;
    object-fit: cover;
`

const HandleAudio = styled.button`
    position: absolute;
    right: 10px;
    bottom: 10px;
    border: none;
    background: none;
    cursor: pointer;
    color: white;
    :focus {
        outline: none;
    }
`

const Item = styled.div`
    height: 100vh;
    background: url(${(props) => props.bg}) no-repeat center center / cover;
    display: ${(props) => (props.active ? 'block' : 'none')};
`
