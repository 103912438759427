import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import renderHTML from 'react-render-html'

function WeatherModal(props) {
    return (
        <Modal isOpen={props.modal} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>Clima en Punta Cana</ModalHeader>
            <ModalBody className="text-center">
                <center>
                    {renderHTML(
                        `<img src="https://www.theweather.com/wimages/foto49fee313a5c60dc0d7bc57331feb1903.png">`
                    )}
                </center>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}
export default WeatherModal
