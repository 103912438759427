import React from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'

function SesionCard(props) {
    const sesion = props.sesion
    const data = props.data
    return (
        <Container active={data.sesion === sesion.id} onClick={props.onClick}>
            <strong className="d-block">
                De {moment(sesion.time_from).format('hh:mm A')} - {moment(sesion.time_to).format('hh:mm A')}
            </strong>
            <small>{sesion.description}</small>
            {data.sesion === sesion.id && (
                <div className="icon-check">
                    <i className="fas fa-check-circle"></i>
                </div>
            )}
        </Container>
    )
}
const Container = styled.div`
    position: relative;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid transparent;
    border-bottom: 1px solid #eee;
    border-radius: 4px;
    .icon-check {
        position: absolute;
        font-size: 1.5rem;
        color: red;
        right: 5%;
        top: 5%;
    }

    ${(props) =>
        props.active &&
        css`
            border-color: red;
        `}
`
export default SesionCard
