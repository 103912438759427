import { API } from '../utils/config'

export function CreateReservation(data) {
    return API.post(`/reservations/`, data)
}

export function GetHashAzul(data) {
    return API.post(`/reservations/hash/`, data)
}

export function GetReservation(uid) {
    return API(`/reservations/${uid}/`)
}
