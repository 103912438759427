import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import renderHTML from 'react-render-html'

function EventCardRow(props) {
    return (
        <Container>
            <Link to={`/detail/${props.event.id}`}>
                <div className="media">
                    <img src={props.event.featured_image} />
                    <div className="media-body">
                        <h5 className="mt-0">{props.event.name}</h5>
                        <div className="media-content">
                            {props.event.description && renderHTML(props.event.description)}
                        </div>
                    </div>
                </div>
            </Link>
        </Container>
    )
}
const Container = styled.div`
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px #0000002e;
    font-size: 1rem;
    a {
        color: #484848;
    }
    a:hover,
    a:active {
        text-decoration: none;
    }
    h5 {
        font-size: 1.2rem;
    }
    img {
        width: 150px;
        height: 150px;
        border-radius: 4px;
        object-fit: cover;
    }
    .media-body {
        padding-left: 1rem;
    }
    .media-content {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        max-height: 115px;
        img {
            display: none;
        }
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        strong {
            font-size: 1rem !important;
            font-weight: 400 !important;
        }
        div {
            display: inline-block !important;
        }
    }
`
export default EventCardRow
